/*
 * @Description: 一级二级三级商品分类下拉框交互逻辑, 目前只适用列表查询页面
 * @Author: LiangYiNing
 * @Date: 2022-03-29 13:51:59
 * @LastEditTime: 2022-03-29 14:31:07
 * @LastEditors: LiangYiNing
 */

import { printError } from "@/utils/util";
import {
  getThirdClassListBySecondClassId,
  getThirdClassListByFirstClassId,
  getSecondClassList,
  getFirstClassList
} from "@/api";

export default {
  data() {
    return {
      searchForm: {
        firstClassId: "",
        secondClassId: "",
        thirdClassId: ""
      },
      thirdClassEnum: [],
      secondClassEnum: [],
      firstClassEnum: [],
      firstClassLoading: false,
      secondClassIdLoading: false,
      thirdClassLoading: false
    };
  },
  created() {
    this.getSecondClassList();
    this.getThirdClassListBySecondClassId();
    this.getFirstClassList();
  },
  methods: {
    // 获取一级级分类枚举
    async getFirstClassList(equipClassId) {
      try {
        this.firstClassLoading = true;
        let res = await getFirstClassList({
          equipClassId
        });
        this.firstClassEnum = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.firstClassLoading = false;
      }
    },
    // 获取二级分类枚举
    async getSecondClassList(equipClassId) {
      try {
        this.secondClassIdLoading = true;
        let res = await getSecondClassList({
          equipClassId
        });
        this.secondClassEnum = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.secondClassIdLoading = false;
      }
    },
    // 获取三级分类枚举
    async getThirdClassListBySecondClassId(equipClassId) {
      try {
        this.thirdClassLoading = true;
        let res = await getThirdClassListBySecondClassId({
          equipClassId
        });
        if (res.code === "OK") {
          this.thirdClassEnum = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.thirdClassLoading = false;
      }
    },
    // 获取三级分类枚举
    async getThirdClassListByFirstClassId(equipClassId) {
      try {
        this.thirdClassLoading = true;
        let res = await getThirdClassListByFirstClassId({
          equipClassId
        });
        if (res.code === "OK") {
          this.thirdClassEnum = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.thirdClassLoading = false;
      }
    },
    // 一级分类选择触发
    firstClassChange() {
      this.searchForm.secondClassId = "";
      this.searchForm.thirdClassId = "";
      this.secondClassEnum = [];
      this.thirdClassEnum = [];
      this.getSecondClassList(this.searchForm.firstClassId);
      this.getThirdClassListByFirstClassId(this.searchForm.firstClassId);
    },
    // 二级分类选择触发
    secondClassChange() {
      this.searchForm.thirdClassId = "";
      this.thirdClassEnum = [];
      this.getThirdClassListBySecondClassId(this.searchForm.secondClassId);
    }
  }
};
