<!--
 * @Description:维护弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="维护物料分类"
      :visible.sync="visible"
      width="80%"
      :before-close="close"
      @open="openDialog"
      v-loading="dialogLoading"
      :close-on-click-modal="false"
      append-to-body
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div>
        <el-input
          v-model="searchForm.classifyName"
          placeholder="分类名称"
          title="分类名称"
          clearable
          style="width:250px;margin-left:10px;"
        ></el-input>
        <el-input
          v-model="searchForm.productLineName"
          placeholder="产品线"
          title="产品线"
          clearable
          style="width:250px;margin-left:10px;"
        ></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button
          type="primary"
          style="margin-left:10px;margin-bottom:10px"
          @click="handleAdd(1)"
          >添加</el-button
        >
        <el-button
          type="primary"
          style="margin-left:10px;margin-bottom:10px"
          @click="handleDelete"
          >删除</el-button
        >
        <el-button
          type="primary"
          style="margin-left:10px;margin-bottom:10px"
          @click="exportData"
          >导出</el-button
        >
        <el-button
          type="primary"
          style="margin-left:10px;margin-bottom:10px"
          @click="importXlxs"
          >导入</el-button
        >
      </div>

      <el-table
        border
        :data="tableData"
        highlight-current-row
        :height="500"
        @selection-change="handleSelectionChange"
        row-key="classifyId"
        ref="dialogTable"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <!--  :reserve-selection="true" -->
        <!-- 序号 start -->
        <el-table-column
          label="序号"
          width="50"
          fixed="left"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>
              {{
                scope.$index +
                  1 +
                  (searchForm.pageIndex - 1) * searchForm.pageSize
              }}
            </span>
          </template>
        </el-table-column>
        <!-- 序号 end -->

        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'isReachFee'">
              <el-switch
                v-model="row.isReachFee"
                :active-value="1"
                :inactive-value="0"
                disabled
              >
              </el-switch>
            </div>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="60"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button type="text" @click="handleAdd(2, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="close">取消</el-button>
        <el-button type="primary" @click="close">确定</el-button>
      </div>
    </el-dialog>
    <!-- 添加物料 -->
    <el-dialog
      v-dialogDrag
      :title="editType == 2 ? '编辑' : '添加'"
      v-loading="addDialogLoading"
      :visible.sync="addVisible"
      width="450px"
      :before-close="
        () => {
          closeDialog('addVisible');
        }
      "
      @open="openAddDialog"
      :close-on-click-modal="false"
      append-to-body
      :element-loading-background="loadingBackground"
      custom-class="add-dialog"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        label-width="120px"
        ref="addForm"
      >
        <el-form-item prop="classifyName" label="物料分类名称">
          <el-input
            v-model="addForm.classifyName"
            placeholder="物料分类名称"
            title="物料分类名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="materialType" label="物料分类类型">
          <e6-vr-select
            v-model="addForm.materialType"
            :data="materialTypeList"
            placeholder="物料类型"
            title="物料类型"
            clearable
            @change="changeMaterialType"
            virtual
            :is-title="true"
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item prop="installPrice" label="新装价格">
          <el-input
            v-model="addForm.installPrice"
            placeholder="新装价格"
            title="新装价格"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="addPrice"
          label="加装价格"
          :rules="
            addForm.materialType == 1
              ? {}
              : [
                  {
                    required: true,
                    message: '请输入加装价格',
                    trigger: ['blur', 'change']
                  }
                ]
          "
        >
          <el-input
            v-model="addForm.addPrice"
            placeholder="加装价格"
            title="加装价格"
            :disabled="addForm.materialType == 1"
          ></el-input>
        </el-form-item>
        <el-form-item prop="repairPrice" label="维修价格">
          <el-input
            v-model="addForm.repairPrice"
            placeholder="维修价格"
            title="维修价格"
          ></el-input>
        </el-form-item>
        <el-form-item prop="unpackPrice" label="拆机价格">
          <el-input
            v-model="addForm.unpackPrice"
            placeholder="拆机价格"
            title="拆机价格"
          ></el-input>
        </el-form-item>
        <el-form-item prop="productLineName" label="产品线">
          <el-input
            v-model="addForm.productLineName"
            placeholder="产品线"
            title="产品线"
          ></el-input>
        </el-form-item>
        <el-form-item prop="isReachFee" label="是否收取上门费">
          <el-switch
            v-model="addForm.isReachFee"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { exportData } from "@/utils/download";
import { e3Api } from "@/config/api";
const { E3_BL_EWOS = "" } = e3Api;
import {
  getMaterialClassifyList,
  saveMaterialClassify,
  exportMaterialClassify,
  updateMaterialClassify,
  findDownList,
  removeMaterialClassify
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "maintain",
  components: {},
  data() {
    return {
      queryListAPI: getMaterialClassifyList,
      addDialogLoading: false,
      dialogLoading: false,
      searchForm: {
        classifyName: "",
        productLineName: "",
        pageIndex: 1,
        pageSize: 20
      },
      classifyIdList: [],
      columnData: [
        {
          fieldName: "classifyNo",
          display: true,
          fieldLabel: "物料分类编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "classifyName",
          display: true,
          fieldLabel: "物料分类",
          width: 90,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installPrice",
          display: true,
          fieldLabel: "新装价格",
          width: 70,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "addPrice",
          display: true,
          fieldLabel: "加装价格",
          width: 70,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "repairPrice",
          display: true,
          fieldLabel: "维修价格",
          width: 70,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "unpackPrice",
          display: true,
          fieldLabel: "拆机价格",
          width: 70,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "productLineName",
          display: true,
          fieldLabel: "产品线",
          width: 110,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isReachFee",
          display: true,
          fieldLabel: "是否收取上门费",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      total: 0,
      addVisible: false,
      editType: 1, //1添加2编辑
      addForm: {
        classifyId: "",
        classifyName: "",
        materialType: "",
        addPrice: "",
        installPrice: "",
        repairPrice: "",
        unpackPrice: "",
        isReachFee: 1,
        productLineName: ""
      },
      addFormRules: {
        classifyName: [
          {
            required: true,
            message: "请输入物料分类",
            trigger: ["blur", "change"]
          }
        ],
        installPrice: [
          {
            required: true,
            message: "请输入新装价格",
            trigger: ["blur", "change"]
          }
        ],
        repairPrice: [
          {
            required: true,
            message: "请输入维修价格",
            trigger: ["blur", "change"]
          }
        ],
        unpackPrice: [
          {
            required: true,
            message: "请输入拆机价格",
            trigger: ["blur", "change"]
          }
        ],
        materialType: [
          {
            required: true,
            message: "请选择物料分类类型",
            trigger: ["blur", "change"]
          }
        ],
        productLineName: [
          {
            required: true,
            message: "请输入产品线名称",
            trigger: ["blur", "change"]
          }
        ],
        isReachFee: [
          {
            required: true,
            message: "请选择是否收上门费",
            trigger: ["blur", "change"]
          }
        ]
      },
      materialTypeList: [],
      clickRow: {}
    };
  },
  props: {
    maintainVisible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage],
  computed: {
    visible: {
      get() {
        return this.maintainVisible;
      },
      set(val) {
        this.$emit("update:maintainVisible", val);
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    openDialog() {
      this.initData();
      this.queryList();
    },
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [findDownList(["slipperMaterialType"])];
        let [res] = await Promise.all(promiseList);
        this.materialTypeList = this.getFreezeResponse(res, {
          path: "data.slipperMaterialType"
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取列表数据 这里只写了分页的 非分页的可自行在组件内覆盖
    async queryList() {
      if (!this.queryListAPI) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.queryListAPI(this.searchForm);
        this.tableData =
          this.formatList && this.formatList !== undefined
            ? this.formatList(res.data.array)
            : this.getFreezeResponse(res, {
                path: "data.array"
              });

        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.dialogTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选id
    handleSelectionChange(columns) {
      this.classifyIdList = columns.map(item => {
        return item.classifyId;
      });
    },
    //点击查询
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.queryList();
    },
    //批量删除
    handleDelete() {
      if (!this.classifyIdList.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.removeMaterialClassifyReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除
    async removeMaterialClassifyReq() {
      try {
        this.loading = true;
        let res = await removeMaterialClassify({
          classifyIdList: this.classifyIdList
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
          this.classifyIdList = [];
          this.$refs.dialogTable.clearSelection();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选择物料分类
    changeMaterialType(val, node) {
      console.log();
      if (node.codeValue == 1) {
        this.addForm.addPrice = "";
      }
    },
    //点击添加
    handleAdd(editType, row) {
      this.addVisible = true;
      this.editType = editType;
      if (editType == 2) {
        this.clickRow = row;
        this.addVisible = true;
        this.addForm.classifyId = row.classifyId;
        this.addForm.isReachFee = row.isReachFee;
        this.addForm.classifyName = row.classifyName;
        this.addForm.materialType = row.materialType + "";
        this.addForm.addPrice = row.addPrice;
        this.addForm.productLineName = row.productLineName;
        this.addForm.installPrice = row.installPrice;
        this.addForm.repairPrice = row.repairPrice;
        this.addForm.unpackPrice = row.unpackPrice;
        this.addForm.classifyNo = row.classifyNo;
      }
    },
    //点击确定提交
    confirmAdd() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.editType == 1) {
            this.saveMaterialClassifyReq();
          } else {
            this.updateMaterialClassifyReq();
          }
        }
      });
    },
    //新建物料分类
    async saveMaterialClassifyReq() {
      try {
        this.addDialogLoading = true;
        let res = await saveMaterialClassify(this.addForm);
        if (res.code == "OK") {
          this.$message.success("新建成功");
          this.closeDialog();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addDialogLoading = false;
      }
    },
    //编辑物料分类
    async updateMaterialClassifyReq() {
      try {
        this.addDialogLoading = true;
        let res = await updateMaterialClassify(this.addForm);
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.closeDialog();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addDialogLoading = false;
      }
    },
    //点击取消
    closeDialog() {
      this.addForm = {
        classifyId: "",
        classifyName: "",
        materialType: "",
        addPrice: "",
        installPrice: "",
        repairPrice: "",
        unpackPrice: "",
        isReachFee: 1,
        productLineName: ""
      };
      this.addVisible = false;
    },
    close() {
      this.searchForm = {
        classifyName: "",
        pageIndex: 1,
        pageSize: 20
      };
      this.tableData = [];
      this.$emit("update:maintainVisible", false);
    },
    openAddDialog() {
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
      this.form = {
        classifyId: "",
        classifyName: "",
        materialType: "",
        installPrice: "",
        repairPrice: "",
        unpackPrice: ""
      };
    },
    //  导出
    exportData() {
      this.$emit("update:maintainVisible", false);
      exportData(this, exportMaterialClassify);
    },
    // 导入
    importXlxs() {
      this.$emit("update:maintainVisible", false);
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "material_classify_import",
          origin: "resourceManagement/materialClassify",
          serverName: E3_BL_EWOS
        }
      };
      this.routerPush(route);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    max-height: 600px;
    .pagination-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
/deep/.add-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .el-input {
      width: 250px;
    }
  }
}
</style>
