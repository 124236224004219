<!--
 * @Description:物料分类列表
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="newMaterialNos">
              <remote-search
                v-model="searchForm.newMaterialNos"
                clearable
                multiple
                placeholder="物料名称"
                title="物料名称"
                :searchData="{
                  localMaterial: 1,
                  pageIndex: 1,
                  pageSize: 200
                }"
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNos">
              <input-plus
                v-model="searchForm.materialNos"
                valueType="Array"
                placeholder="物料编码"
                title="物料编码"
                label="物料编码"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item prop="materialTypy">
              <e6-vr-select
                v-model="searchForm.materialTypy"
                :data="materialClassifyTypeList"
                placeholder="物料类型"
                title="物料类型"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="status">
              <e6-vr-select
                v-model="searchForm.status"
                :data="serviceProviderStatusList"
                placeholder="单据状态"
                title="单据状态"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="classifyName">
              <el-input
                v-model="searchForm.classifyName"
                placeholder="物料分类"
                title="物料分类"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="classifyNos">
              <remote-search
                v-model="searchForm.classifyNos"
                clearable
                multiple
                :queryListAPI="getMaterialClassifyList"
                placeholder="物料分类名称"
                title="物料分类名称"
                :props="{
                  id: 'classifyNo',
                  label: 'classifyName'
                }"
                searchValueKey="classifyName"
              ></remote-search>
              <!-- <el-input
                v-model="searchForm.classifyNo"
                placeholder="物料分类编码"
                title="物料分类编码"
              ></el-input> -->
              <!-- <input-plus
                v-model="searchForm.classifyNos"
                valueType="Array"
                placeholder="物料分类编码"
                title="物料分类编码"
                label="物料分类编码"
                clearable
              ></input-plus> -->
            </el-form-item>
            <el-form-item class="search-item--1" prop="firstClassIdList">
              <e6-vr-select
                v-model="searchForm.firstClassIdList"
                :loading="firstClassLoading"
                :data="firstClassEnum"
                placeholder="设备一级分类"
                title="设备一级分类"
                :props="{
                  id: 'firstClassId',
                  label: 'firstClassName'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondClassIdList">
              <e6-vr-select
                v-model="searchForm.secondClassIdList"
                :data="secondClassEnum"
                :loading="secondClassIdLoading"
                placeholder="设备二级分类"
                title="设备二级分类"
                :props="{
                  id: 'secondClassId',
                  label: 'secondClassName'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdClassIdList">
              <e6-vr-select
                v-model="searchForm.thirdClassIdList"
                :data="thirdClassEnum"
                :loading="thirdClassLoading"
                placeholder="设备三级分类"
                title="设备三级分类"
                multiple
                :props="{
                  id: 'thirdClassId',
                  label: 'thirdClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button type="primary" @click="handleMaintain"
            >物料分类维护</el-button
          >
          <el-button type="primary" @click="handleBind">绑定物料分类</el-button>
          <el-button type="primary" @click="handleNobind"
            >无需绑定物料分类</el-button
          >
          <!-- <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
          <i class="e6-icon-Import_fill" title="导入" @click="importXlxs()"></i> -->
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
          :row-key="
            row => {
              return row.materialNo;
            }
          "
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>

          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <maintain :addVisible.sync="addVisible"></maintain>
    <!-- 绑定物料分类 -->
    <el-dialog
      v-dialogDrag
      title="绑定物料分类"
      :visible.sync="bindVisible"
      width="400px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      append-to-body
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="bind-dialog"
    >
      <el-form :model="bindForm" :rules="bindFormRules" ref="bindForm">
        <el-form-item prop="classifyId" label="物料分类">
          <e6-vr-select
            v-model="bindForm.classifyId"
            :data="classifyList"
            placeholder="物料分类"
            title="物料分类"
            clearable
            virtual
            :is-title="true"
            :props="{
              id: 'classifyId',
              label: 'classifyName'
            }"
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmBind">确定</el-button>
      </div>
    </el-dialog>
    <!-- 维护物料列表 -->
    <maintain :maintainVisible.sync="maintainVisible"></maintain>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import inputPlus from "@/components/inputPlus";
import goodsClassInteractions from "@/mixins/commomFormInteractions/goodsClassInteractions";
import {
  getMaterialRelationListPage,
  getMaterialClassifyList,
  batchUpdateStatus,
  findDownList
} from "@/api";
import maintain from "./maintain.vue";
export default {
  name: "materialClassify",
  data() {
    return {
      queryListAPI: getMaterialRelationListPage,
      getMaterialClassifyList,
      searchForm: {
        materialName: "",
        newMaterialNos: [],
        materialNos: [],
        materialTypy: "",
        firstClassIdList: [],
        secondClassIdList: [],
        thirdClassIdList: [],
        status: "",
        classifyNo: "",
        classifyName: "",
        classifyNos: [],
        pageIndex: 1,
        pageSize: 20
      },
      firstClassEnum: [],
      secondClassEnum: [],
      thirdClassEnum: [],
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "设备一级分类名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "设备二级分类名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "设备三级分类名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialTypyName",
          display: true,
          fieldLabel: "物料类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "单据状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "classifyName",
          display: true,
          fieldLabel: "物料分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "classifyNo",
          display: true,
          fieldLabel: "物料分类编码",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updateUser",
          display: true,
          fieldLabel: "操作员",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updateTime",
          display: true,
          fieldLabel: "最后修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      demoList: [],
      materialNos: [],
      addVisible: false,
      bindVisible: false,
      bindForm: {
        classifyId: ""
      },
      bindFormRules: {
        classifyId: [
          {
            required: true,
            message: "请选择物料分类",
            trigger: ["blur", "change"]
          }
        ]
      },
      maintainVisible: false,
      materialClassifyTypeList: [],
      serviceProviderStatusList: [],
      classifyList: [],
      dialogLoading: false
    };
  },
  mixins: [listPage, base, listPageReszie, goodsClassInteractions],
  components: {
    maintain,
    inputPlus
  },
  computed: {},
  watch: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        let promiseList = [
          findDownList(["slipperMaterialType", "materialClassifyType"]),
          getMaterialClassifyList({})
        ];
        let [res, classifyRes] = await Promise.all(promiseList);
        this.materialClassifyTypeList = this.getFreezeResponse(res, {
          path: "data.slipperMaterialType"
        });
        this.serviceProviderStatusList = this.getFreezeResponse(res, {
          path: "data.materialClassifyType"
        });
        this.classifyList = this.getFreezeResponse(classifyRes, {
          path: "data.array"
        });
      } catch (error) {
        console.error(error);
      }
    },
    //表格选中数据
    handleSelectionChange(column) {
      this.materialNos = column.map(item => item.materialNo);
    },
    //维护物料列表
    handleMaintain() {
      this.maintainVisible = true;
    },
    //点击绑定物料
    handleBind() {
      if (!this.materialNos.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.bindVisible = true;
      this.initData();
    },
    //点击无需绑定
    handleNobind() {
      if (!this.materialNos.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("确认这些物料都无需绑定分类吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.batchUpdateStatusReq(3);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //新增
    handleAdd() {
      this.addVisible = true;
    },
    closeDialog() {
      this.bindForm.classifyId = "";
      this.bindVisible = false;
    },
    //确认绑定
    confirmBind() {
      this.$refs.bindForm.validate(valid => {
        if (valid) {
          this.batchUpdateStatusReq(1, this.bindForm.classifyId);
        }
      });
    },
    //更新绑定状态
    async batchUpdateStatusReq(status, classifyId) {
      try {
        this.dialogLoading = true;
        let res = await batchUpdateStatus({
          materialNos: this.materialNos,
          status,
          classifyId: classifyId ? classifyId : ""
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.closeDialog();
          this.handleSearch();
          this.$refs.elTable.clearSelection();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.bind-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .el-input {
      width: 250px;
    }
  }
}
</style>
